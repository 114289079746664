$primaryColor: #223672;
;
$inputColor: #74829c;
$inputErrorColor: #c60000;
$borerColor: #eeeeee;
$iconColor: #223672;


$primaryFont: "IBM Plex Sans";
$decorationFont: "Orbitron";
$textColor: #282f53;

$errorColor: #c60000;
$successColor: #37b70c;
$infoColor: #e2c036;

$tableBorderColor: #eee;