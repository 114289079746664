@import '../../../assets/scss/variables.scss';

.input {

    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    position: relative;


    &__label {
        display: block;
        margin-bottom: 5px;
        font-size: 12px;
        flex-basis: 100%;
        max-height: 15px;
    }

    &__field {
        max-height: 38px;
        height: 38px;
        width: 100%;
        border: 1px solid $inputColor;
        border-radius: 5px;
    }

    &__photo {
        padding: 5px;
        width: 160px;
        height: 160px;
        background-color: white;
        border: 1px solid $inputColor;
        border-radius: 5px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__file-name {
        overflow: hidden;
        max-width: 160px;
        padding: 5px 10px;
        background-color: white;
        border: 1px solid $inputColor;
        border-radius: 5px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__file-button {
        max-width: 50%;
        position: absolute;
        left: 170px;
        bottom: 5px;
        width: 150px !important;
        // min-width: none !important;
        height: 35px;
    }
}


.field {
    position: relative;

    &__icon {
        position: absolute;
        width: 38px;
        height: 100%;
        padding: 12px 0;
        color: $inputColor;
        border-right: 1px solid $inputColor;
        cursor: pointer;
    }

    &__content {
        height: 100%;
        width: 100%;
        padding: 10px;
        flex-grow: 1;
        border: none;
        border-radius: 5px;
        color: $textColor;
        outline: none;

        &:focus {
            background-color: #eeeeee;
        }

        &--file {
            display: none;
            margin-top: 15px;
            border: none !important;
            outline: none !important;
        }

        &--color {
            padding: 2px;
            cursor: pointer;
        }

        &--file-button {
            display: none;
            margin-top: 15px;
            border: none;
            outline: none !important;
        }


    }


}

.field--validation-error {
    border-color: $inputErrorColor;
    flex-grow: 1;

    .field__icon {
        border-right: 1px solid $inputErrorColor;
    }
}

.field--icon {
    .field__content {
        padding-left: 48px;
    }
}

.field--file {
    height: 0;
    width: 0;
}

.field--color {
    padding: 2px;
    width: 38px;
}