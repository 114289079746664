.admin-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__select {
        flex-basis: 49%;
    }

    &__input {
        flex-basis: 49%;
    }

    &__button {
        margin-top: 50px;
        margin-bottom: 100px;
        justify-self: center;
        flex-basis: 33%;
    }
}

@media(max-width: 640px) {
    .admin-form {

        &__input {
            flex-basis: 100%;
        }

        &__select {
            flex-basis: 100%;
        }

        &__button {

            flex-basis: 100%;

        }
    }
}