@import '../../assets/scss/variables.scss';

.page-header {
    background-color: white;
}

.header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $borerColor;


    &__logo {
        flex-basis: 270px;
        height: 60%;
        border-right: 1px solid $borerColor;

        background-repeat: no-repeat;
        background-position: center;
        background-size: 95%;
        background-size: contain;
    }

    &__menu {
        flex-basis: calc(100% - 270px);
    }
}

@media(max-width: 1000px) {
    .header {
        position: relative;

        &__logo {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 270px;
            transform: translate(-50%, -50%);
            border: none;
        }

        &__menu {
            flex-basis: 100%;
        }

    }

}