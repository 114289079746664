.background {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(156, 150, 150);
    opacity: 0.5;

    &--image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: 1;
    }
}