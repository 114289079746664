@import '../../../assets/scss/variables.scss';

.header-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;

    &__icon {
        &--navbar {
            display: none;
        }
    }

    &__tools {
        flex-basis: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__modal {


        .modal__container {
            max-width: 1000px;
            width: 90vw;
        }
    }

}

.tools {
    &__icon {
        width: 28px;
        height: 28px;
        margin-left: 20px;
    }

    &__profile {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        cursor: pointer;
    }
}

@media(max-width: 1000px) {
    .header-menu {
        position: relative;
        justify-content: space-between;

        &__icon {
            &--navbar {
                display: block;
            }
        }

    }
}