@import '../../../assets/scss/variables.scss';

.confirm {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__message {
        flex-basis: 100%;
        text-align: center;
    }

    &__item {
        flex-basis: 100%;
        text-align: center;
        margin-bottom: 30px;
    }

    &__button {
        flex-basis: 45%;

        &--delete {
            button {
                background-color: $errorColor ;
            }

            color: white;
        }
    }
}