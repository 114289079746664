@import '../../../assets/scss/variables';

.alert {
    width: 100%;
    height: 40px;
    display: flex;
    border-radius: 5px;
    margin-bottom: 5px;
    align-items: center;
    font-size: 14px;
    color: $textColor;

    &__icon {
        height: 50%;
        width: auto;
        padding: 0 10px;
    }

    &__message {
        color: $textColor;
    }

}

.success {
    background-color: lighten($successColor, 50%);
}

.success .alert {
    &__icon {
        fill: $successColor
    }
}

.error {
    background-color: lighten($errorColor, 50%);
}

.error .alert {
    &__icon {
        fill: $errorColor
    }
}

.info {
    background-color: lighten($infoColor, 30%);
}

.info .alert {
    &__icon {
        fill: $infoColor
    }
}