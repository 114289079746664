@import '../../../assets/scss/variables.scss';

.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;

    &__container {
        position: absolute;
        top: 50%;
        left: 50%;


        transform: translate(-50%, -50%);
        color: $textColor;
        border-radius: 5px;
        box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .06);
        background-color: white
    }
}

.container {
    &__header {
        width: 100%;

        padding: 20px;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        border-bottom: 1px solid $tableBorderColor;

    }

    &__content {
        max-height: 80vh;
        overflow-y: auto;
        padding: 20px;
        margin-bottom: 5px;
    }
}