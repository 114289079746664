.login {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;

    .login-form__button {
        margin-top: 40px;
    }

    &__link {
        text-align: center;
        font-size: 14px;
    }
}