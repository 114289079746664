@import '../../../../assets/scss/variables.scss';

.table-header {
    text-align: left;
    padding: 10px;
    height: 40px;
    border: 1px solid $tableBorderColor;
    cursor: pointer;
    position: relative;

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__icon {
        display: block;
        padding-left: 10px;
        width: 30px;

        svg {
            color: $tableBorderColor;
        }

        &--ascending {
            svg {
                color: $successColor;
            }
        }

        &--descending {
            svg {
                color: $errorColor;
            }
        }
    }
}