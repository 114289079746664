@import "../../../assets/scss/variables.scss";

.button {
    width: 100%;
    text-align: center;

    &__pressable {
        height: 38px;

        border-radius: 5px;
        width: 100%;
        font-size: 14px;
        letter-spacing: 1px;
        border: none;
        background-color: $primaryColor;
        color: white;
        cursor: pointer;

        &:disabled {
            cursor: auto;
            opacity: 0.7;
        }
    }
}