@import '../../../assets/scss/variables.scss';

.select {
    margin-bottom: 15px;

    &__label {
        display: block;
        margin-bottom: 5px;
        font-size: 12px;
    }

    &__field {
        height: 38px;
        width: 100%;
        padding: 0 10px;
        border: 1px solid $inputColor;
        border-radius: 5px;
        font-size: 12px;
        color: $textColor;
        outline: none !important;

        option {
            color: $textColor;
            line-height: 20px;
            font-size: 12px;
        }
    }
}

.dropdown {
    position: relative;
    display: block;
    width: 100%;

    &__trigger {
        display: flex;
        align-items: center;
        padding-left: 20px;
        height: 38px;
        width: 100%;
        border: 1px solid $inputColor;
        border-radius: 5px;
        font-size: 12px;
        justify-content: space-between;
    }

    &__content {
        position: absolute;
        top: 110%;
        width: 100%;
        background-color: white;
        border: 1px solid $inputColor;
        border-radius: 5px;
        z-index: 1;
    }
}

.trigger {
    &__icon {
        border-left: 1px solid $inputColor;
        width: 40px;
        margin-left: 20px;

        svg {
            color: $inputColor !important;
        }
    }
}

.content {
    max-height: 300px;
    overflow-y: auto;

    &__option {
        height: 38px;
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 0 10px;
        cursor: pointer;

        &--selected {
            background-color: $primaryColor !important;
            color: white !important;
            opacity: 1 !important;
        }


    }



    &__option:hover {
        background-color: lighten($primaryColor, 10%);
        color: white;
    }


    &__search {
        .field {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $inputColor;
        }
    }

    .multi:hover {
        background-color: transparent;
        color: $textColor;
    }
}

.disabled {
    opacity: 50%;
}

.disabled:hover {
    background-color: transparent;
    color: $textColor;
    cursor: auto;
}