@import '../../../assets/scss/variables.scss';

.icon-button {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: none;
    ;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
    background-color: $primaryColor;

    &:disabled {
        cursor: auto;
        opacity: 80%;
    }
}