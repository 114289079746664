@import '../../assets/scss/variables.scss';

.login-modal {
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 500px;
    padding: 32px;
    color: $textColor;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .06);
    background-color: white;

    &__title {
        text-align: center;
        font-size: 24px;
        margin-bottom: 25px;
    }

    &__header {
        position: absolute;
        left: 50%;
        top: -40px;
        min-width: 300px;
        color: white;
        font-size: 24px;
        text-align: center;
        letter-spacing: 2px;
        transform: translateX(-50%);
        font-family: $decorationFont;
    }

    .logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 40px;
        bottom: 40px;
    }

}